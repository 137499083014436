import { FC, ReactNode } from 'react';
import Image from 'next/image';
import { useDisclosure } from '@mantine/hooks';
import cn from 'classnames';
import { CloseIcon } from '@/shared/assets/svg';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import styles from './alert.module.scss';

interface AlertBlockProps {
  title?: string;
  subtitle?: ReactNode;
  className?: string;
  withoutClose?: boolean;
}

const defaulTtitle = 'Сайт обновляется';
const defaultSubtitle = '⚠️ Уважаемые пользователи! Сообщаем Вам, что на сайте ведутся технические работы. Приносим свои извинения за возможные неудобства';

export const AlertBlock: FC<AlertBlockProps> = ({
  title,
  subtitle, 
  className,
  withoutClose
}) => {
  const { isMobile } = useMediaQuery();
  const [opened, { close }] = useDisclosure(true);

  if(!opened) return null;

  return (
    <div className={cn(styles.container, className)}>
      {isMobile ? null : (
        <Image
          className={styles.image}
          src='/assets/img/alert.svg'
          alt='Сайт обновляется'
          width={66}
          height={66}
        />
      )}
      <div className={styles.text}>
        <span className={styles.title}>{title || defaulTtitle}</span>
        <span className={styles.subtitle}>{subtitle || defaultSubtitle}</span>
      </div>
      {withoutClose ? null : (
        <button type='button' className={styles.close} onClick={close}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};
