import { FC } from 'react';
import { useRouter } from 'next/router';
import { Button, Modal } from '@/shared/ui-kit';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { Path } from '@/shared/constants/links';
import styles from './unavailable-popup.module.scss';

interface UnavailablePopupProps {
  opened: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  link?: string;
  onClick?: () => void;
}

const defaultTitle = 'Сервис временно недоступен';
const defaultSubtitle = 'По техническим причинам временно не продаем SIM-карты онлайн. Приглашаем купить их в наших фирменных салонах. Скоро все исправим!';

export const UnavailablePopup: FC<UnavailablePopupProps> = ({ opened, onClose, title, subtitle, buttonLabel, onClick }) => {
  const { isMobile } = useMediaQuery();
  const router = useRouter();

  const clickHandler = () => {
    onClick || router.push(Path.pointsOfSale);
  }

  const content = (
    <div className={styles.container}>
      <h4 className={styles.title}>{title ?? defaultTitle}</h4>
      <span className={styles.subtitle}>{subtitle ?? defaultSubtitle}</span>
      <Button onClick={clickHandler} rootClassName={styles.btn} btnType='magenta' >
        {buttonLabel ?? 'Выбрать салон связи'}
      </Button>
    </div>
  );

  return isMobile ? 
    <BottomSheet open={opened} onDismiss={onClose}>{content}</BottomSheet> : (
    <Modal opened={opened} onClose={onClose}>
      {content}
    </Modal>
  );
};
